import { AppRouter, GraphAppRouter, SearchAppRouter} from 'trpc-status-page';
import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import { Auth } from 'aws-amplify';

const getBaseUrl = () => {
  return process.env.SECRET_TRPC_ENDPOINT??'https://randomtrcpurl.com';
};


export const trpcClient = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      /**
       * If you want to use SSR, you need to use the server's full URL
       * @link https://trpc.io/docs/ssr
       **/
      url: `${getBaseUrl()}/trpc`,
      // You can pass any HTTP headers you wish here
      async headers() {
        return {
          // @ts-ignore
          Authorization: (await Auth.currentSession()).getAccessToken().jwtToken,
        };
      },
    }),
  ],
  transformer: undefined
});


export const trpcGraphClient = createTRPCProxyClient<GraphAppRouter>({
  links: [
    httpBatchLink({
      /**
       * If you want to use SSR, you need to use the server's full URL
       * @link https://trpc.io/docs/ssr
       **/
      url: `${getBaseUrl()}/trpc/graph/`,
      // You can pass any HTTP headers you wish here
      async headers() {
        return {
          // @ts-ignore
          Authorization: (await Auth.currentSession()).getAccessToken().jwtToken,
        };
      },
    }),
  ],
  transformer: undefined
});

export const trpcSearchClient = createTRPCProxyClient<SearchAppRouter>({
  links: [
    httpBatchLink({
      /**
       * If you want to use SSR, you need to use the server's full URL
       * @link https://trpc.io/docs/ssr
       **/
      url: `${getBaseUrl()}/trpc/search/`,
      // You can pass any HTTP headers you wish here
      async headers() {
        return {
          // @ts-ignore
          Authorization: (await Auth.currentSession()).getAccessToken().jwtToken,
        };
      },
    }),
  ],
  transformer: undefined
});