import "../css/style.css";
import React from "react";
import Layout from "../components/layout";
import { Amplify } from "aws-amplify";
import { AwsRum } from 'aws-rum-web';
import { useRouter } from "next/router";
import SideBar from "../components/sidebar";

let myAppConfig = {
  aws_project_region: process.env.SECRET_APP_AWS_REGION,
  aws_cognito_region: process.env.SECRET_APP_AWS_REGION,
  aws_user_pools_id: process.env.SECRET_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.SECRET_AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_appsync_graphqlEndpoint: process.env.SECRET_AWS_APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_region: process.env.SECRET_APP_AWS_REGION,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "null",
  aws_user_files_s3_bucket_region: process.env.SECRET_APP_AWS_REGION,
  aws_user_files_s3_bucket: process.env.SECRET_BUCKET_NAME,
};
Amplify.configure({ ...myAppConfig, ssr: true });

export default function MyApp({ Component, pageProps }) {
  try {
    const config = {
      sessionSampleRate: 1,
      guestRoleArn: process.env.RUM_IDENTITY_POOL_GUEST_ARN,
      identityPoolId: process.env.RUM_IDENTITY_POOL_ID,
      endpoint: "https://dataplane.rum.ap-southeast-2.amazonaws.com",
      telemetries: ["performance","errors","http"],
      allowCookies: true,
      enableXRay: true
    };
  
    const APPLICATION_ID = process.env.RUM_APPLICATION_ID;
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = process.env.SECRET_APP_AWS_REGION;
  
    const awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }

  const router  = useRouter();

  return (
    <div className="pt-4">
      <script src="https://app.lemonsqueezy.com/js/lemon.js" defer></script>
      {router.asPath === '/' ? (
        <Component {...pageProps} />
      ): (
        <SideBar>
          <Component {...pageProps} />
      </SideBar>
      )}
      
    </div>
  );
}
