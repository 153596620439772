import Link from "next/link";

import { Container } from "./container";

export default function Footer() {
  return (
    <footer className="bg-slate-100 font-serif">
      <Container>
        <div className="flex items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          <div className="flex gap-x-5">
            <Link
              href="https://www.linkedin.com/company/cloud-shuttle/"
              className="group"
              aria-label="Cloudshuttle Linkedin"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="30"
                height="30"
                viewBox="0 0 50 50"
              >
                <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
              </svg>
            </Link>
          </div>
          <div>
            <p className="mt-6 text-lg text-slate-500 sm:mt-0">
              Copyright &copy; {new Date().getFullYear()} Cloud Shuttle Pty Ltd. All
              rights reserved.
            </p>
            <p className="mt-6 text-base text-slate-500 sm:mt-0">
              Oatley, NSW 2223.
            </p>
            <p className="mt-6 text-base text-slate-500 sm:mt-0">
              ABN: 94 638 159 136
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
}
