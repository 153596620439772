import { trpcClient } from "@/trpc";
import { z } from "zod";
import { ZSubscriptionOutput } from 'trpc-status-page/trpc/schema';


const getCompanySubscription =async  (companyId: string)
: Promise<z.infer<typeof ZSubscriptionOutput>> => {
  const data = await trpcClient.getSubscription.query({
    company_id: companyId
  });

  return data;
}

const updateCompanySubscription = async (
  companyId: string,
  subscriptionId: string
): Promise<z.infer<typeof ZSubscriptionOutput>> => {

  const data = await trpcClient.putSubscription.mutate({
    companyId: companyId,
    subid: subscriptionId
  });

  return data;
};

const getProductStoreVariants = async (): Promise<Record<string, string[]>> => {

  const data = await trpcClient.getProductVariants.query();

  return data
}

export {
  getCompanySubscription,
  updateCompanySubscription,
  getProductStoreVariants
}